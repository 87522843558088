<template>
  <div class="customer__tender__description">
    <TenderDesc :draftTender="draftTender" :subTags="subTags"></TenderDesc>
    <div class="customer__tender__description__buttons__container">
      <router-link
        class="customer__tender__description__button"
        :to="{
          name: `Main`,
        }"
      >
        <Button  style="height: 40px" buttonClass="red-antd-button-without-width">
          Закрыть
        </Button>
      </router-link>
    </div>
  </div>
</template>

<script>
import Button from '@/components/common/Button.vue'
import TenderDesc from '@/components/Tender/TenderDesc'
import { getBearer } from '@/helpers/authHelper'

export default {
  props: ['draftTender', 'subTags'],
  components: {
    Button,
    TenderDesc
  },
  data () {
    return {
      customUrl: process.env.VUE_APP_SERVER_URL
    }
  },
  methods: {
    getDraftTenderSubtags () {
      const filterArr = this.subTags.filter((subtag) => {
        for (let id of this.draftTender.subtagsId) {
          if (id === subtag.id) {
            return subtag
          }
        }
      })

      return filterArr
    },

    deactivateTender () {
      const url = process.env.VUE_APP_SERVER_URL + `/tenders/${this.draftTender.uuid}`
      fetch(url, {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: getBearer()
        },
        body: JSON.stringify({ statusId: 3 })
      })
        .then((response) => response.json())
        .then((res) => {
          this.$notification.open({
            message: 'Вы завершили тендер!',
            description: res.message
          })
        })

        .catch((error) => {
          this.$notification.open({
            message: 'Вы не смогли завершить тендер!',
            description: error
          })
        })
    }
  }
}
</script>

<style lang="scss">
.customer__tender__description {
  display: flex;
  flex-direction: column;
  padding: 26px 20px 40px 30px;
  justify-content: space-around;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;

  background-color: white;
  border-radius: 3px;

  &__pre-word-status {
    display: flex;
    flex-direction: row;
  }
  &__buttons__container {
    display: flex;
    justify-content: flex-start;
  }
}

</style>
