var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile-master-wrapper"},[(this.$store.getters.getTenderLoaded)?_c('Breadcrumb',{attrs:{"newPath":this.$store.getters.getTender.name,"mainLabelName":this.$store.getters.getTender.name,"breadcrumbs":_vm.breadcrumbs}}):_vm._e(),_c('a-tabs',[_c('a-tab-pane',{key:"1",attrs:{"tab":"Тендер"},on:{"click":_vm.testTabClick}},[_c('TabViewContainer',[(
            this.$store.getters.getTenderLoaded &&
            this.$store.getters.getSubTagsLoaded
          )?_c('div',{staticClass:"tender_wrapper"},[(!_vm.isCurrentUser)?_c('CustomerTenderDescription',{attrs:{"draftTender":this.$store.getters.getTender,"subTags":this.$store.getters.getSubTags}}):_vm._e(),(this.$store.getters.getTender.status.id == 1 && _vm.isCurrentUser)?_c('CustomerTenderDescriptionStatusOne',{attrs:{"draftTender":this.$store.getters.getTender,"isCustomerRedact":true,"subTags":this.$store.getters.getSubTags}}):_vm._e(),(this.$store.getters.getTender.status.id == 2)?_c('CustomerTenderDescriptionStatusTwo',{attrs:{"draftTender":this.$store.getters.getTender,"subTags":this.$store.getters.getSubTags}}):_vm._e(),(this.$store.getters.getTender.status.id == 3)?_c('CustomerTenderDescriptionStatusThree',{attrs:{"draftTender":this.$store.getters.getTender,"isCustomerRedact":true,"subTags":this.$store.getters.getSubTags}}):_vm._e()],1):_vm._e()])],1),(_vm.isCurrentUser)?_c('a-tab-pane',{key:"2",attrs:{"tab":"Участники"}},[_c('TabViewContainer',[(!this.$store.getters.getTenderResponsesLoaded)?_c('Loader'):_vm._e(),(this.$store.getters.getTenderResponsesLoaded)?_c('div',{staticClass:"tender_wrapper"},[(
              this.$store.getters.getTenderResponses.length != 0
            )?_c('Chats',{attrs:{"responses":this.$store.getters.getTenderResponses},on:{"openDialogEvent":_vm.openDialogEvent}}):_vm._e(),(
              this.$store.getters.getTenderResponses.length != 0 && _vm.isOpenChat
            )?_c('Chat',{attrs:{"response":_vm.openChat,"tenderUUID":_vm.openChat.tender.uuid,"userUUID":_vm.openChat.user.uuid},on:{"closeDialogEvent":_vm.closeDialogEvent}}):_vm._e(),(this.$store.getters.getTenderResponses.length == 0)?_c('NoMessage'):_vm._e()],1):_vm._e()],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }