<template>
  <div class="profile-master-wrapper">
    <Breadcrumb
      v-if="this.$store.getters.getTenderLoaded"
      :newPath="this.$store.getters.getTender.name"
      :mainLabelName="this.$store.getters.getTender.name"
      :breadcrumbs="breadcrumbs"
    ></Breadcrumb>
    <a-tabs>
      <a-tab-pane @click="testTabClick" key="1" tab="Тендер">
        <TabViewContainer>
          <div
            v-if="
              this.$store.getters.getTenderLoaded &&
              this.$store.getters.getSubTagsLoaded
            "
            class="tender_wrapper"
          >
            <CustomerTenderDescription v-if="!isCurrentUser"  :draftTender="this.$store.getters.getTender" :subTags="this.$store.getters.getSubTags"></CustomerTenderDescription>
            <CustomerTenderDescriptionStatusOne
              v-if="this.$store.getters.getTender.status.id == 1 && isCurrentUser"
              :draftTender="this.$store.getters.getTender"
              :isCustomerRedact="true"
              :subTags="this.$store.getters.getSubTags"
            ></CustomerTenderDescriptionStatusOne>
            <CustomerTenderDescriptionStatusTwo
              v-if="this.$store.getters.getTender.status.id == 2"
              :draftTender="this.$store.getters.getTender"
              :subTags="this.$store.getters.getSubTags"
            ></CustomerTenderDescriptionStatusTwo>
            <CustomerTenderDescriptionStatusThree
              v-if="this.$store.getters.getTender.status.id == 3"
              :draftTender="this.$store.getters.getTender"
              :isCustomerRedact="true"
              :subTags="this.$store.getters.getSubTags"
            ></CustomerTenderDescriptionStatusThree>
          </div>
        </TabViewContainer>
      </a-tab-pane>
      <a-tab-pane v-if="isCurrentUser" key="2" tab="Участники">
        <TabViewContainer>
          <Loader v-if="!this.$store.getters.getTenderResponsesLoaded" />
          <div v-if="this.$store.getters.getTenderResponsesLoaded" class="tender_wrapper">
            <Chats
              :responses="this.$store.getters.getTenderResponses"
              v-if="
                this.$store.getters.getTenderResponses.length != 0
              "
              @openDialogEvent="openDialogEvent"
            ></Chats>
            <Chat
              :response="openChat"
              :tenderUUID="openChat.tender.uuid"
              :userUUID="openChat.user.uuid"
              v-if="
                this.$store.getters.getTenderResponses.length != 0 && isOpenChat
              "
              @closeDialogEvent="closeDialogEvent"
            ></Chat>
            <NoMessage
              v-if="this.$store.getters.getTenderResponses.length == 0"
            />
          </div>
        </TabViewContainer>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import CustomerTenderDescriptionStatusOne from '@/components/CustomerComponents/CustomerTenderDescriptionStatusOne.vue'
import CustomerTenderDescriptionStatusTwo from '@/components/CustomerComponents/CustomerTenderDescriptionStatusTwo.vue'
import CustomerTenderDescriptionStatusThree from '@/components/CustomerComponents/CustomerTenderDescriptionStatusThree.vue'
import CustomerTenderDescription from '@/components/CustomerComponents/CustomerTenderDescription.vue'
import TabViewContainer from '@/components/common/TabViewContainer.vue'
import NoMessage from '@/components/Chat/NoMessage.vue'
import Chats from '@/components/Chat/Chats.vue'
import Chat from '@/components/Chat/Chat.vue'
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import { getUUID } from '@/helpers/authHelper'
import Loader from '@/components/common/Loader.vue'


export default {
  mounted () {
    this.$store.dispatch('setSubTags')
    this.$store.dispatch('setTender', this.uuid)
    this.$store.dispatch('setTenderResponses', this.uuid)
  },
  props: ['uuid'],
  components: {
    CustomerTenderDescriptionStatusOne,
    CustomerTenderDescriptionStatusTwo,
    CustomerTenderDescriptionStatusThree,
    CustomerTenderDescription,
    TabViewContainer,
    NoMessage,
    Chats,
    Chat,
    Breadcrumb,
    Loader
  },

  data () {
    return {
      responsesLoaded: false,
      messages: [],
      isOpenChat: false,
      openChat: {},
      breadcrumbs: [
        {
          id: 1,
          name: 'Главная',
          link: '/main'
        },
        {
          id: 2,
          name: 'Все Тендеры',
          link: '/tenders'
        }
      ]
    }
  },
  computed: {
    isCurrentUser () {
      if (this.$store.getters.getTender.customer && this.$store.getters.getTender.customer.uuid && this.$store.getters.getTender.customer.uuid === getUUID()) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    transferTender () {
      const tender = this.$store.getters.getTender
      return tender
    },
    openDialogEvent (item) {
      this.openChat = item
      this.isOpenChat = true
    },
    closeDialogEvent () {
      this.isOpenChat = false
    },
    testTabClick () {
    }
  }
}
</script>

<style>
.profile-master-wrapper {
  background-color: white;
  width: 100%;
  height: 100%;
}

.profile-label {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.profile-master-personal-tab-container {
  background-color: #f6f6f6;
  padding-right: 15%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 20px;
  padding-top: 17px;
  padding-bottom: 15%;
}

.tender_wrapper{
  width: 100%;
  height: 100%;
}
</style>
