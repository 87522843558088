var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"customer__tender__description__three"},[_c('TenderDesc',{attrs:{"draftTender":_vm.draftTender,"subTags":_vm.subTags}}),_c('div',{staticClass:"customer__tender__description__three__buttons__container"},[(_vm.draftTender.statusId == 3 && _vm.draftTender.winnerId)?_c('Button',{staticClass:"red-antd-button-without-width",staticStyle:{"height":"40px"},on:{"click":_vm.toggleReviewModal}},[_vm._v("Оставить Отзыв")]):_vm._e(),_c('span',{style:({
         display: 'inline-block',
         width: '24px',
         textAlign: 'center',
       })}),(_vm.draftTender.statusId == 3)?_c('router-link',{staticClass:"customer__tender__description__three__button",attrs:{"to":{
         name: `MyTenders`,
         params: { uuid: _vm.draftTender.uuid },
       }}},[(_vm.draftTender.statusId == 3)?_c('Button',{staticClass:"white-antd-button-without-width",staticStyle:{"height":"40px"},on:{"click":_vm.deleteTender}},[_vm._v("В архив")]):_vm._e()],1):_vm._e()],1),(_vm.draftTender.winner && _vm.draftTender.winnerId)?_c('a-modal',{attrs:{"footer":null,"visible":_vm.reviewVisible},on:{"cancel":_vm.toggleReviewModal}},[_c('ReviewModal',{attrs:{"image":_vm.draftTender.winner && _vm.draftTender.winner.image ? _vm.draftTender.winner.image : undefined,"winnerId":_vm.draftTender.winnerId,"tenderUUID":_vm.draftTender.uuid},on:{"okButtonReviewModule":_vm.toggleReviewModal}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }