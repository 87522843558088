<template>
  <div class="customer__tender__description__two">
 <TenderDesc :draftTender="draftTender" :subTags="subTags"></TenderDesc>

    <div class="customer__tender__description__two__buttons__container">
      <router-link
        v-if="draftTender.statusId == 2"
        :to="{
          name: `Tender`,
          params: { uuid: draftTender.uuid },
        }"
      >
        <Button
         style="height: 40px"
          @click="deactivateTender"
          buttonClass="red-antd-button-without-width"
        >
          Завершить
        </Button>
      </router-link>
    </div>
  </div>
</template>

<script>
import ServiceWork from '@/components/common/ServiceWork.vue'
import Button from '@/components/common/Button.vue'
import TenderDesc from '@/components/Tender/TenderDesc'
import { getBearer } from '@/helpers/authHelper'

export default {
  props: ['draftTender', 'subTags'],
  components: {
    ServiceWork,
    Button,
    TenderDesc
  },
  data () {
    return {
      reviewVisible: false,
      customUrl: process.env.VUE_APP_SERVER_URL
    }
  },
  methods: {
    toggleReviewModal () {
      this.reviewVisible = !this.reviewVisible
    },
    getDraftTenderSubtags () {
      const filterArr = this.subTags.filter((subtag) => {
        for (let id of this.draftTender.subtagsId) {
          if (id === subtag.id) {
            return subtag
          }
        }
      })

      return filterArr
    },

    deactivateTender () {
      const url = process.env.VUE_APP_SERVER_URL + `/tenders/${this.draftTender.uuid}`
      fetch(url, {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: getBearer()
        },
        body: JSON.stringify({ statusId: 3 })
      })
        .then((response) => response.json())
        .then((res) => {
          this.$notification.open({
            message: 'Вы завершили тендер!',
            description: res.message
          })
        })
        .catch((error) => {
          this.$notification.open({
            message: 'Вы не смогли завершить тендер!',
            description: error
          })
        })
    }
  }
}
</script>

<style lang="scss">
.customer__tender__description__two {
  display: flex;
  flex-direction: column;
  padding: 26px 20px 40px 30px;
  justify-content: space-around;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;

  background-color: white;
  border-radius: 3px;
  &__date__and__price__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__date {
    color: #989898;
    text-align: left;
  }

  &__price {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    color: black;
  }
  &__image {
    margin-bottom: 20px;
  }

  &__name__and__status__container {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    color: black;
  }

  &__pre-word-status {
    display: flex;
    flex-direction: row;
  }

  &__status {
    &__1 {
      color: #7fb56c;
    }
    &__2 {
      color: #b5b46c;
    }
    &__3 {
      color: #fa491c;
    }
  }

  &__services-work {
    margin-top: 10px;
  }

  &__description {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;

    margin-top: 14px;
    padding-right: 35%;
  }
  &__buttons__container {
    display: flex;
    justify-content: flex-start;
  }
  &__button {
    margin-right: 12px;
  }
}

@media (max-width: 992px) {
  .customer__tender__description__one {
    &__date__and__price__container {
      flex-direction: column;
    }
    &__date {
      margin-bottom: 20px;
    }
    &__price {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;
    }
  }
}
</style>
