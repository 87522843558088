<template>
  <div class="tender-chat-container">
    <Dialog
      v-for="response in responses"
      :key="response.requestId"
      :chat="response"
      @clickDialog="openDialogEvent(response)"
      :isSingle="false"
    >
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/Chat/Dialog.vue'

export default {
  props: ['responses'],
  components: {
    Dialog
  },
  methods: {
    openDialogEvent (response) {
      this.$emit('openDialogEvent', response)
    }
  }
}
</script>

<style>
.tender-chat-container {
  background-color: white;
  padding: 39px;
}
</style>
